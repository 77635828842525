.contact-container {
  display: flex;
  flex-direction: column;
  margin: 50px 0 50px;
  padding: 10px 150px;
}

.contact-container .title {
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}

.contact-container .contact-top {
  margin-top: 20px;
}

.contact-container .contact-top iframe {
  width: 100%;
  height: 300px;
  border-radius: 15px;
  border: 0;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.2);
}

.contact-container .contact-bottom {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-top: 20px;
}

.contact-container .contact-bottom .item-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: 100ms ease-in-out;
}

.contact-container .contact-bottom .item-container:hover {
  color: rgb(159, 159, 159);
}

.contact-container .contact-bottom .item-container i {
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.2);
}

@media (max-width: 1000px) {
  .contact-container {
    padding: 10px 50px;
  }
}

@media (max-width: 800px) {
  .contact-container .contact-title {
    font-size: 20px;
  }

  .contact-container .contact-bottom {
    font-size: 14px;
  }
}

@media (max-width: 560px) {
  .contact-container .contact-bottom {
    flex-direction: column;
    gap: 5px;
  }
}
