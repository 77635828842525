.aims-container {
  display: flex;
  flex-direction: column;
  margin: 20px 150px;
}

.objectives-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 15px;
}

.line {
  width: 5px;
  height: 40px;
  background-color: rgb(26, 26, 97);
  border-radius: 0 3px 3px 0;
}

.objectives-container .title {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(26, 26, 97);
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 10px;
}

.objectives-container .objectives-description {
  font-weight: 400;
  font-size: 20px;
  margin-top: 10px;
}

.objectives-container .objectives-box {
  margin-top: 10px;
}

.objectives-container .objectives-box ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 300;
}

.objectives-container .objectives-box ul li {
  list-style: decimal;
  color: rgb(26, 26, 97);
  font-size: 20px;
}

.social-media {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 70px;
  padding: 5px;
  gap: 20px;
}

.social-media .icon {
  width: 30px;
  height: 30px;
  box-shadow: 0px 0 5px rgb(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 50ms ease-in-out;
  cursor: pointer;
}

.social-media .icon i {
  color: rgb(111, 111, 61);
}

.social-media .icon:hover {
  box-shadow: 0px 0 5px rgb(0, 0, 0, 0.2);
}

@media (max-width: 1000px) {
  .aims-container {
    margin: 20px 30px;
  }

  .social-media {
    display: flex;
    flex-direction: row;
    right: 20px;
    justify-content: flex-end;
  }
}

@media (max-width: 800px) {
  .objectives-container .title {
    font-size: 20px;
  }

  .line {
    width: 4px;
    height: 25px;
  }

  .objectives-container .objectives-description {
    font-size: 1rem;
    margin-top: 10px;
  }

  .objectives-container .objectives-box ul li {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .aims-container {
    margin: 10px 0;
  }

  .social-media {
    display: flex;
    flex-direction: row;
    right: 20px;
    justify-content: flex-end;
  }
}
