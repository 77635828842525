.about-container {
  display: flex;
  flex-direction: column;
  padding: 20px 150px 40px;
  color: white;
  margin-top: 50px;
  background-color: #006480;
}

.about-container .title {
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 10px;
}

.about-container .line {
  background-color: white;
}

.about-description-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.description-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.about-description-container .description {
  font-weight: 300;
  font-size: 20px;
  text-align: justify;
  text-justify: inter-word;
}

.about-description-container .image {
  border-radius: 5px;
  height: 100px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
}

.about-description-container .image-container {
  padding-right: 20px;
}

@media (max-width: 1200px) {
  .about-description-container .image {
  }

  .about-description-container .description {
    font-size: 1rem;
  }
}

@media (max-width: 1000px) {
  .about-container {
    padding: 20px 50px;
    background-size: 50%;
  }
}

@media (max-width: 800px) {
  .about-container .about-title {
    font-size: 20px;
  }

  .about-description-container .image {
    /* height: 200px; */
  }

  .about-description-container {
    flex-direction: column;
    align-items: center;
  }
}
