.services-container {
  display: flex;
  flex-direction: column;
  margin: 50px 150px;
  align-items: center;
}

.services-container .title {
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}

.services-container .services-description {
  font-weight: 400;
  font-size: 20px;
  margin-top: 10px;
}

.services-container ul {
  list-style: none;
  display: flex;
  gap: 50px;
  margin-top: 50px;
  padding: 0;
}

hr {
  width: 100%;
  border: 1px solid;
  margin: 0;
}

@media (max-width: 1200px) {
  .services-container ul {
    display: grid;
    grid-template-columns: 2fr 2fr;
  }
}

@media (max-width: 1000px) {
  .services-container {
    margin: 50px;
  }
}

@media (max-width: 800px) {
  .services-container .services-title {
    font-size: 20px;
  }

  .services-container .services-description {
    font-size: 1rem;
  }
}

@media (max-width: 430px) {
  .services-container ul {
    grid-template-columns: 1fr;
  }
}
