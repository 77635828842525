.navbar-wrapper {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.navbar-container {
  padding: 10px;
  padding-right: 30px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}

.navbar-container.white {
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.2);
  background-color: white;
}

.navbar-container .left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-container .left-side img {
  width: 100px;
  cursor: pointer;
}

.navbar-container .left-side .logo-two {
  font-size: 8px;
  color: blue;
  font-weight: 600;
}

.navbar-container .right-side {
  display: flex;
  align-items: center;
}

.navbar-container .right-side ul {
  display: flex;
  gap: 40px;
}

.navbar-container .right-side ul li {
  list-style: none;
  cursor: pointer;
  color: rgb(255, 255, 255, 0.6);
  transition: 100ms ease-in-out;
  font-weight: 700;
}

.navbar-container.white .right-side ul li {
  color: rgb(0, 0, 0, 0.5);
}

.navbar-container .right-side ul li:hover {
  color: rgb(0, 0, 0, 1);
}

.navbar-container .right-side ul li.active {
  color: rgb(0, 0, 0, 1);
}

.hamburger {
  display: none;
}

.navbar-container .left-side img.hide {
  display: none;
}

.navbar-container .left-side .logo-two.hide {
  display: none;
}

/* Styles for screens with a width of 800px or less */
@media screen and (max-width: 800px) {
  .navbar-container {
    box-shadow: 0 0 10px rgb(0, 0, 0, 0.2);
    background-color: white;
  }

  .navbar-container .right-side ul li {
    color: rgb(0, 0, 0, 0.5);
  }

  .navbar-container .left-side img {
    width: 70px;
  }

  .navbar-container .left-side img.hide {
    display: block;
  }

  .navbar-container .left-side .logo-two {
    font-size: 6px;
  }

  .navbar-container .left-side .logo-two.hide {
    display: block;
  }

  .right-side {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 0;
    flex-direction: column;
    align-items: center;
  }

  .right-side.open {
    max-height: 300px;
  }

  .list-container {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }

  .list-container li {
    text-align: center;
    padding: 10px 0;
    margin: 0;
    border-top: 1px solid #ddd;
    /* border: 1px solid red; */
  }

  .navbar-container .right-side ul {
    gap: 10px;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    font-size: 24px;
  }

  .right-side {
    display: none;
  }

  .right-side.show {
    display: flex;
  }
}
