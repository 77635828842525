.footer-container {
  display: flex;
  flex-direction: column;
  padding: 10px 150px 0;
  background-color: rgb(12, 63, 77);
  color: white;
}

.footer-container .section-one {
  display: flex;
  justify-content: space-between;
}

.footer-container .section-one .title {
  font-size: 20px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  margin-top: 10px;
}

.footer-container .section-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0 20px;
  font-size: 12px;
}

@media (max-width: 1000px) {
  .footer-container {
    padding: 50px;
  }
}

@media (max-width: 700px) {
  .footer-container .section-one .title {
    font-size: 20px;
  }

  .footer-container .section-one {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .footer-container {
    padding: 20px;
  }
}
