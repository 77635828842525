.home-container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  /* background-color: ; */
}

.home-container .image-container {
  /* position: ; */
  z-index: -1;
  width: 100vw;
  overflow: hidden;
  max-height: 100vh;
  min-width: 1000px;
}

.title-container {
  top: 130px;
  left: 20px;
  /* padding-left: 150px; */
  font-weight: 200;
  color: white;
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

.title-container .title {
  font-weight: 700;
  font-size: 5rem;
}

.title-container .subtitle {
  font-size: 20px;
  font-weight: 600;
  opacity: 0.7;
}

.title-container .description {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  color: rgb(0, 0, 0);
  width: 800px;
  /* text-align: center; */
  font-style: italic;
}

.service-scroll-container {
  z-index: 10;
  cursor: pointer;
}

.service-scroll {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-size: 14px;
  font-weight: 700;
}

.service-scroll-container i {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  opacity: 0; /* set initial opacity to 0 */
  animation: fade-in-out 2s ease-in-out infinite; /* apply the animation */
}

.service-scroll-container i.two {
  bottom: 45px;
  font-size: 8px;
  color: white;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media (max-width: 1380px) {
  .title-container .title {
    font-size: 3rem;
  }

  .title-container .subtitle {
    font-size: 1rem;
  }

  .title-container .description {
    width: 600px;
    font-size: 1rem;
  }
}

@media (max-width: 975px) {
  .title-container .title {
    font-size: 3rem;
  }

  .title-container .subtitle {
    font-size: 12px;
  }

  .title-container .description {
    width: 400px;
  }
}

@media (max-width: 440px) {
  .title-container .title {
    font-size: 2rem;
  }
  .title-container .description {
    font-size: 14px;
    width: 350px;
  }
}

@media (max-width: 360px) {
  .title-container .description {
    width: 300px;
  }
}
